$('.main__slider').slick({
	autoplay: true,
	autoplaySpeed: 4000,
	arrows: false,
	dots: true
});


const headerSetting = () => {
	const headerElms = {
		orig: document.querySelector('#menu'),
	};

	if (!headerElms.orig) {
		return;
	}

	headerElms.dummy = headerElms.orig.cloneNode(true);
	headerElms.dummy.removeAttribute('id');
	headerElms.dummy.style.opacity = 0;
	headerElms.dummy.style.visibility = 'hidden';
	headerElms.dummy.style.zIndex = -1;
	headerElms.dummy.style.pointerEvents = 'none';
	headerElms.orig.insertAdjacentElement('afterend', headerElms.dummy);

	const _scroll = () => {
		if (headerElms.dummy.getBoundingClientRect().top < 0) {
			if (!headerElms.orig.classList.contains('fix')) {
				headerElms.orig.classList.add('fix');
			}
		} else {
			if (headerElms.orig.classList.contains('fix')) {
				headerElms.orig.classList.remove('fix');
			}
		}
	};

	window.addEventListener('load', _scroll, false);
	window.addEventListener('scroll', _scroll, false);
};

headerSetting();
